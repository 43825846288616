"use strict";

var _objectFitImages = _interopRequireDefault(require("object-fit-images"));
var _topMenu = _interopRequireDefault(require("./top-menu"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
(0, _objectFitImages.default)(null, {
  watchMQ: true
});
_topMenu.default.topMenu();
document.querySelector('body').addEventListener('click', function (e) {
  if (e.target && e.target.classList.contains('next-page')) {
    e.preventDefault();
    var request = new XMLHttpRequest();
    request.open('GET', e.target.getAttribute('href'), true);
    request.onload = function () {
      if (request.status >= 200 && request.status < 400) {
        e.target.outerHTML = request.responseText;
      }
    };
    request.send();
  }
});