"use strict";

/* eslint no-param-reassign: 0 */

function toggleClass(el, className) {
  if (el.classList) {
    el.classList.toggle(className);
  } else {
    var classes = el.className.split(' ');
    var existingIndex = classes.indexOf(className);
    if (existingIndex >= 0) {
      classes.splice(existingIndex, 1);
    } else {
      classes.push(className);
    }
    el.className = classes.join(' ');
  }
}
exports.topMenu = function () {
  var mobileNavigationItems = document.querySelectorAll('.page-header__container');
  setupNavigation(mobileNavigationItems);
  setupSearch(mobileNavigationItems);
};
function setupNavigation(mobileNavigationItems) {
  var mobileNavigation = document.querySelectorAll('.page-header__toggle--menu')[0];
  if (!mobileNavigation || !mobileNavigationItems) {
    return;
  }
  mobileNavigation.addEventListener('click', function () {
    toggleClass(mobileNavigation, 'icon--cross');
    toggleClass(mobileNavigation, 'icon--hamburger');
    for (var i = 0; i < mobileNavigationItems.length; i++) {
      toggleClass(mobileNavigationItems[i], 'page-header__container--open-menu');
    }
  });
}
function setupSearch(mobileNavigationItems) {
  var searchToggle = document.querySelectorAll('.page-header__toggle--search')[0];
  if (!searchToggle || !mobileNavigationItems) {
    return;
  }
  searchToggle.addEventListener('click', function () {
    toggleClass(searchToggle, 'icon--cross');
    toggleClass(searchToggle, 'icon--search');
    for (var i = 0; i < mobileNavigationItems.length; i++) {
      toggleClass(mobileNavigationItems[i], 'page-header__container--open-search');
      document.querySelector('.page-header__search-input').focus();
    }
  });
}