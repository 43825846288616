"use strict";

require("xp-lib-brand/index");
require("lazysizes/plugins/respimg/ls.respimg.js");
require("lazysizes");
require("./js/search-show-more");
var _stickybits = _interopRequireDefault(require("stickybits"));
var _hiraku = _interopRequireDefault(require("hiraku"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// import xp-lib-brand js first

var leftMenu = new _hiraku.default('.offcanvas-left', {
  btn: '#offcanvas-btn-left',
  direction: 'left',
  width: '100%'
});
var rightMenu = new _hiraku.default('.offcanvas-right', {
  btn: '#offcanvas-btn-right',
  direction: 'right',
  width: '100%'
});
leftMenu.on('open', function () {
  document.getElementById('page-header__search-input').focus();
});
document.getElementById('offcanvas-btn-right-close').addEventListener('click', function () {
  rightMenu.close();
});
document.getElementById('offcanvas-btn-left-close').addEventListener('click', function () {
  leftMenu.close();
});
(0, _stickybits.default)('.gryr-page-header');