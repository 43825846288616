import objectFitImages from 'object-fit-images';
import topMenu from './top-menu';

objectFitImages(null, { watchMQ: true });
topMenu.topMenu();

document.querySelector('body').addEventListener('click', (e) => {
	if (e.target && e.target.classList.contains('next-page')) {
		e.preventDefault();
		const request = new XMLHttpRequest();
		request.open('GET', e.target.getAttribute('href'), true);
		request.onload = () => {
			if (request.status >= 200 && request.status < 400) {
				e.target.outerHTML = request.responseText;
			}
		};
		request.send();
	}
});
